.container{
    /* background: rgb(21,32,116);
    background: linear-gradient(0deg, rgba(21,32,116,1) 0%, rgba(45,180,253,1) 100%); */
    background-image: url("./question.png");
    background-size: 100% 100%;
    color: whitesmoke;
    width: 90%;
    margin-left: 5%;
    text-align: center;
    font-size: 3vw;
    padding: 0.6vw 0;
      
}