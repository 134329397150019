.container{
    background-image: url(../../../../assets/back.jpg);
    position: absolute;
    top: 0px;
    height: 100vh;
    width: 100vw;
    align-items: center;
    display: flex;
    justify-content: center;
}

.text{
    color: white;
    font-size: 15vw;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}