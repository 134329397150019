.container {
    position: absolute;
    top:0px;
    width: 100vw;
    height: 100vh;
    align-items: center;
    display: flex;
    justify-content: center;
}

.table{
    background-image: url(../../assets/back.jpg);
    width: 60%;
    height: 90%;
    border-radius: 20px;
    padding: 3vw;
}

.tableInside {
    background-color: aliceblue;
    border-radius: 20px;
    padding: 1vw;
    max-height: 90%;
    overflow:auto;
    min-height: 90%;
}

.playerContainer{
    font-size: 2vw;
    padding: 0.5vw;
    font-family:Arial, Helvetica, sans-serif;
    font-weight: 600;
    color: rgb(0, 140, 255);
    width: 100%;
    margin-top: 0.7vh;
    display: flex;
    border-bottom: 0.3vh solid grey;
}

.nick {
    width: 50%;
}

.points{
    width: 50%;
}

.medalImage{
    width: 50px;
    margin-left: 1vw;
    margin-bottom: -15px;
}

.button{
    background-color: #ff471a;
    color: white;
    font-size: 2vw;
    border-width: 0;
    padding: 0.5vh 2vw;
    border-radius: 10px;
    margin: 1vw;
    transition-duration: 0.5s;
    transition-property: all;
}

.button:hover{
    background-color: #8b270e;
    cursor: pointer;
}