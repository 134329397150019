.container {
    /* background-color: yellow; */
    position: absolute;
    bottom: 0px;
    width: 100%;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    transition-property: margin-bottom;
    transition-duration: 1s;
}

.element {
    width: 100%;
}

.hidden {
    margin-bottom: -50vh;
}

.visible {
    margin-bottom: 0px;
}