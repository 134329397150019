.container {
    background-image: url(https://pixy.org/download/4752612/);
    background-size: cover;
    padding: 100px;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100vh;
    width: 100vw;
}

.formContainer{
    background-color: white;
    padding: 2vw;
    border-radius: 10px;
    width: 25vw;
}

.formTitle{
    font-size: 2vw;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    color: red;
    text-align: center;
    margin-bottom: 1vw;
}

.formInput{
    font-size: 1.5vw;
    text-align: center;
    border-radius: 10px;
    border-width: 2;
    border-color: rgb(149, 206, 255);
}

.formButton{
    color: white;
    background-color: green;
    border-radius: 10px;
    padding: 0.7vw;
    font-size: 2vw;
    border-width: 0;
    margin-top: 1vh;
    transition-property: all;
    transition-duration: 0.5s;
}

.formButton:hover{
    background-color: rgb(0, 82, 0);
    cursor: pointer;
}

.buttonContainer{
    display: flex;
    align-items: center;
    justify-content: center;
}