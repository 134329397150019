
.container {
    width: 40%;
    margin-left: 6.5%;
    text-align: center;
    color: white;
    font-size: 2vw;
    padding: 1vw;
    background-size: 100% 100%;
}


.colorBlue {
    background-image: url("/src/assets/answer-blue.png");
}
.colorRed {
    background-image: url("/src/assets/answer-red.png");
}
.colorGreen {
    background-image: url("/src/assets/answer-green.png");
}
.colorYellow {
    background-image: url("/src/assets/answer-yellow.png");
}