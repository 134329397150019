* {
  box-sizing: border-box;
}
  html,body,#root{
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body{
  background: #f1f4f8;
  position: fixed;
}