
.container {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:rgb(0, 0, 0);
    opacity: 0.8;
}

.textLoading{
    color: white;
    margin-top: 180px;
    font-size: 3vw;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.textCode{
    color: white;
    margin-top: 1vw;
    font-size: 4vw;
    text-align: center;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.loadingContainer{
    
    padding: 50px;
}

.loader{
    position: absolute;
}


.loaderContent{
    display: flex;
    justify-content: center;
    padding: 50px;
}